// src/admin/components/networkSettings/network_settings_edit_create.tsx
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  required,
  NumberInput,
  maxLength,
} from "react-admin";

const NetworkSettingsForm = () => (
  <SimpleForm>
    <NumberInput source="network_id" validate={required()} />

    <TextInput source="logo_url" />
    <TextInput source="icon_url" />
    <TextInput source="banner_url" />
    <TextInput source="description" multiline rows={3} validate={required()} />
    <TextInput
      source="short_description"
      validate={[required(), maxLength(50)]}
      helperText="Maximum 50 characters"
    />
  </SimpleForm>
);

export const NetworkSettingsEdit = () => (
  <Edit>
    <NetworkSettingsForm />
  </Edit>
);

export const NetworkSettingsCreate = () => (
  <Create>
    <NetworkSettingsForm />
  </Create>
);
