// src/admin/components/networkSettings/network_settings_list.tsx
import { List, Datagrid, TextField } from "react-admin";

export const NetworkSettingsList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="network_id" />
      <TextField source="main_color" />
      <TextField source="accent_color" />
      <TextField source="background_color" />
      <TextField source="text_color" />
      <TextField source="short_description" />
      <TextField source="banner_url" />
    </Datagrid>
  </List>
);
