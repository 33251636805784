// src/admin/components/networkDetails/network_details_list.tsx
import { List, Datagrid, TextField, EmailField } from "react-admin";

export const NetworkDetailsList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="network_id" />
      <TextField source="legal_name" />
      <TextField source="corporate_id" />
      <EmailField source="email" />
      <TextField source="city" />
      <TextField source="country_code" />
      <TextField source="network_slug" />
    </Datagrid>
  </List>
);
