import React from "react";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/joy";
import { ITicketType } from "../../../types";
import usePalette, { useIsDarkTheme } from "../../../theme/use_palette";
import {
  ShoppingCartItem,
  addTicket,
  confirmSwitchRelease,
  promptSwitchRelease,
  removeTicket,
  setCurrentTicketReleaseId,
  switchEvent,
} from "../../../redux/features/ticketOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { toast } from "react-toastify";
import { numberOfTotalTicketRequestInBasket } from "../../../utils/ticket_types";
import useMediaQuery from "@mui/material/useMediaQuery";
import InfoIcon from "@mui/icons-material/Info";
import {
  selectAccentColor,
  selectMainColor,
} from "../../../redux/features/managerThemeSlice";
import {
  adjustTransparency,
  darkerColor,
  isColorDark,
  lightenColor,
} from "../../../utils/manager/color";
import StyledButton from "../../buttons/styled_button";
import StyledText from "../../text/styled_text";
import zIndex from "@mui/material/styles/zIndex";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";

interface TicketTypeProps {
  ticketType: ITicketType;
  maxTicketsPerUser: number;
}

const TicketType: React.FC<TicketTypeProps> = ({
  ticketType,
  maxTicketsPerUser,
}) => {
  const palette = usePalette();
  const { items } = useSelector((state: RootState) => state.ticketOrder) as {
    items: ShoppingCartItem[];
  };
  const [plusDisabled, setPlusDisabled] = React.useState<boolean>(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const mainColor = useSelector(selectMainColor) || palette.primaryMain;
  const accentColor = useSelector(selectAccentColor) || palette.primaryMain;
  const darkMode = useIsDarkTheme();

  const { currentTicketReleaseId, currentEventId } = useSelector(
    (state: RootState) => state.ticketOrder
  );

  const dispatch: AppDispatch = useDispatch();

  const handleAddTicket = (ticket: ITicketType) => {
    if (
      currentTicketReleaseId &&
      currentTicketReleaseId !== ticket.ticket_release_id
    ) {
      // Prompt the user to confirm clearing the cart
      dispatch(promptSwitchRelease(ticket));
      if (currentEventId !== ticket.event_id) {
        dispatch(switchEvent(ticket.event_id));
        dispatch(confirmSwitchRelease());
      }
    } else {
      if (!currentTicketReleaseId) {
        dispatch(
          setCurrentTicketReleaseId({
            ticketReleaseId: ticket.ticket_release_id!,
            eventId: ticket.event_id,
          })
        );
      }

      const numberOfTotalTickets = numberOfTotalTicketRequestInBasket(
        items,
        ticket.ticket_release_id!
      );
      if (numberOfTotalTickets >= maxTicketsPerUser) {
        toast.error(
          `You can only purchase a maximum of ${maxTicketsPerUser} tickets`
        );
        return;
      }

      if (numberOfTotalTickets + 1 >= maxTicketsPerUser) {
        setPlusDisabled(true);
      } else {
        setPlusDisabled(false);
      }

      dispatch(addTicket(ticket));
    }
  };

  const handleRemoveTicket = (ticket: ITicketType) => {
    setPlusDisabled(false);
    dispatch(removeTicket(ticket));
  };

  const quantity = items.filter(
    (item) => item.ticket.id === ticketType.id
  ).length;

  const calculateFontSize = (str: string) => {
    const length = str.length;
    if (length <= 10) {
      return isScreenSmall ? 16 : 24;
    } else if (length <= 20) {
      return isScreenSmall ? 14 : 20;
    } else {
      return isScreenSmall ? 12 : 18;
    }
  };

  const buttonBgColor = darkMode ? palette.offWhite : palette.greyLight;

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: 600,
        margin: "16px auto",
        backgroundColor: adjustTransparency(palette.isabellineExtraDark, 0.3),
        borderRadius: "8px",
        border: `3px outset ${palette.primaryExtraLight}`,
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        height: "auto", // Adjust height
      }}
    >
      {/* Left Side - Ticket Info */}
      <Box
        sx={{
          flex: 1,
          padding: 2, // Adjust padding
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box display="flex" alignItems="center" mb={1}>
          <Tooltip
            title={ticketType.description || ""}
            placement="top"
            arrow
            sx={{
              maxWidth: 400,
            }}
          >
            <InfoIcon sx={{ color: palette.primaryDark, mr: 1 }} />
          </Tooltip>
          <Typography
            fontWeight={700}
            fontSize={calculateFontSize(ticketType.name)}
            sx={{
              overflowWrap: "break-word",
              maxWidth: "100%",
              color: palette.primaryDark,
            }}
          >
            {ticketType.name}
          </Typography>
        </Box>
        <Typography
          fontWeight={500}
          fontSize={18}
          sx={{ color: palette.primaryDark }}
        >
          SEK {ticketType.price}
        </Typography>
      </Box>

      {/* Right Side - Quantity Controls */}
      <Box
        sx={{
          width: 80, // Adjust width
          color: isColorDark(accentColor) ? palette.white : palette.black,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* Vertical 'Ticket' Text */}
        <StyledText
          sx={{
            position: "absolute",
            top: "50%",
            right: 64,
            transform: "translateY(-50%) rotate(90deg)",
            transformOrigin: "center",
            fontSize: 14,
            fontWeight: 700,
            textTransform: "uppercase",
            letterSpacing: 2,
            zIndex: 10,
          }}
          color={palette.primaryMain}
          level="body-md"
        >
          Ticket
        </StyledText>

        {/* Quantity Controls */}
        <Box
          sx={{
            width: 80,
            borderLeft: `2px dashed ${palette.primaryMain}`,
            color: isColorDark(accentColor) ? palette.white : palette.black,
            display: "grid",
            gridTemplateRows: "1fr 1fr 1fr", // Three equal rows
            height: "100%",
            position: "relative",
          }}
        >
          {/* Plus Button */}
          <IconButton
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              transition: "background-color 0.2s",
              borderRadius: 0,
              bgcolor: adjustTransparency(buttonBgColor, 0.5),
              "&:hover": {
                backgroundColor: palette.greyDark,
              },
            }}
            onClick={() => {
              !plusDisabled && handleAddTicket(ticketType);
            }}
            disabled={plusDisabled}
          >
            <AddIcon
              sx={{
                color: plusDisabled ? palette.greyDark : palette.black,
                borderRadius: 0,
                fontSize: 28,
              }}
            >
              +
            </AddIcon>
          </IconButton>

          {/* Count Display */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              borderTop: `1px solid ${palette.primaryExtraLight}`,
              borderBottom: `1px solid ${palette.primaryExtraLight}`,
              backgroundColor: buttonBgColor,
            }}
          >
            <StyledText
              level="body-lg"
              color={palette.black}
              fontWeight={700}
              fontSize={22}
            >
              {quantity || 0}
            </StyledText>
          </Box>

          {/* Minus Button */}

          <IconButton
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              transition: "background-color 0.2s",
              borderRadius: 0,
              bgcolor: adjustTransparency(buttonBgColor, 0.5),
              "&:hover": {
                backgroundColor: palette.greyDark,
              },
            }}
            disabled={quantity === 0}
            onClick={() => {
              quantity > 0 && handleRemoveTicket(ticketType);
            }}
          >
            <RemoveIcon
              sx={{
                color: quantity === 0 ? palette.greyDark : palette.black,
                borderRadius: 0,
                fontSize: 28,
              }}
            >
              -
            </RemoveIcon>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TicketType;
