import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  TextField,
  SelectField,
  Button,
  ReferenceManyField,
} from "react-admin";
import { Link } from "react-router-dom";
import { applicationStatusChoices } from "../networkMerchant/network_merchant_list";

const LinkButton = ({ record, reference, label }: any) => (
  <Button
    component={Link}
    to={`/${reference}/${record?.id}/edit`}
    label={label}
  />
);

export const NetworkCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput source="plan_enrollment_id" reference="plan-enrollments">
        <AutocompleteInput optionText="reference_name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const NetworkEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <ReferenceInput source="plan_enrollment_id" reference="plan-enrollments">
        <AutocompleteInput optionText="reference_name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
