// src/admin/components/networkDetails/network_details_edit_create.tsx
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  required,
  NumberInput,
} from "react-admin";

const NetworkDetailsForm = () => (
  <SimpleForm>
    <NumberInput source="network_id" validate={required()} />
    <TextInput source="legal_name" validate={required()} />
    <TextInput source="corporate_id" validate={required()} />
    <TextInput source="description" multiline rows={3} />
    <TextInput source="care_of" />
    <TextInput source="address_line1" />
    <TextInput source="address_line2" />
    <TextInput source="postal_code" />
    <TextInput source="city" />
    <NumberInput source="phone_code" />
    <TextInput source="phone_number" />
    <TextInput source="country_code" />
    <TextInput source="email" type="email" validate={required()} />
    <TextInput source="terms_and_conditions_url" />
    <TextInput source="privacy_policy_url" />
    <TextInput source="network_slug" validate={required()} />
  </SimpleForm>
);

export const NetworkDetailsEdit = () => (
  <Edit>
    <NetworkDetailsForm />
  </Edit>
);

export const NetworkDetailsCreate = () => (
  <Create>
    <NetworkDetailsForm />
  </Create>
);
