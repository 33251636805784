// src/admin/components/networkMerchant/network_merchant_list.tsx
import { List, Datagrid, TextField, SelectField } from "react-admin";

export const applicationStatusChoices = [
  { id: "application_not_started", name: "Not Started" },
  { id: "application_initiated", name: "Initiated" },
  { id: "application_submitted", name: "Submitted" },
  { id: "application_pending_information", name: "Pending Information" },
  { id: "application_signed", name: "Signed" },
  { id: "application_rejected", name: "Rejected" },
  { id: "application_completed", name: "Completed" },
  { id: "application_expired", name: "Expired" },
  { id: "merchant_created", name: "Merchant Created" },
];

export const NetworkMerchantList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="network_id" />
      <TextField source="merchant_id" />
      <SelectField
        source="application_status"
        choices={applicationStatusChoices}
      />
      <TextField source="store_id" />
      <SelectField
        source="fee_transaction_plan"
        choices={[
          { id: "TSSR_STANDARD", name: "Standard" },
          { id: "TSSR_NON_PROFIT", name: "Non-Profit" },
        ]}
      />
    </Datagrid>
  </List>
);
